<template>
  <div class="home">
    <div class="navigation">
      <span @click="goMedia">下载中心</span>
      <i class="el-icon-arrow-right"></i>
      <span class="nav_info">{{ dataForm.title }}</span>
    </div>
    <div class="content">
      <div class="title">{{ dataForm.title }}</div>
      <div class="msg">
        <span>{{ dataForm.publishTime }}</span>
        <!-- <span>浏览量：{{ dataForm.downloadTime }}</span> -->
      </div>
             <div class="ql-container ql-snow">
                  <div class="ql-editor">
                     <div class="iframe" v-html="dataForm.infoContent"></div>
                  </div>
                </div>
           
     
      <ul>
        <li v-for="item in dataForm.attachUrl" :key="item.id">
          <span>{{ item.name }}</span>
          <div
            class="downloadIcon"
            @click="downloadFile(item.response, item.name)"
          >
            <section>
              <span>下载</span>
              <img src="../../assets/recommend/downloadIcon.png" alt="" />
            </section>

            <span>{{ dataForm.publishTime }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "book",
  data() {
    return {
      open: true,
      dataForm: {},
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    decode(base64) {
      // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      return base64;
    },
    goMedia() {
      this.$router.push({
        path: "/download",
        query: {},
      });
    },
    downloadFile(href, name) {
      // var blob = new Blob(['Hello, world!'], {
      //   type: 'text/plain;charset=utf-8',
      // });

      // 存在浏览器兼容性
      // let href = URL.createObjectURL(blob);

      let alink = document.createElement("a");
      alink.style.display = "none";
      // alink.download = name; //下载后文件名
      console.log(name, alink.download);
      alink.href = href;
      document.body.appendChild(alink);
      alink.click();
      document.body.removeChild(alink); //下载完成移除元素

      // URL.revokeObjectURL(href); //释放掉blob对象
    },
    getInfo() {
      this.$http
        .get(`/downloadcenter/getDownloadLoadById`, {
          params: { id: this.$route.query.id },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.dataForm = {
            ...res.data,
            infoContent: this.decode(res.data.content),
            // catalogue:this.decode(res.data.catalogue),

            attachUrl: JSON.parse(res.data.attachUrl),
          };
          console.log(this.dataForm, "dataform");
        })
        .catch(() => {});
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.home {
  padding: 0.32rem 0.64rem;
  padding-top: 1.16rem;
  .navigation {
    position: absolute;
    left: 0.64rem;
    top: 0.7rem;
    span {
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.2rem;
      cursor: pointer;
    }
    .nav_info {
      font-size: 0.14rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 0.2rem;
    }
    i {
      color: rgba(178, 1, 3, 1);
      font-size: 0.16rem;
      margin: 0.05rem;
      font-weight: 600;
    }
  }
  .content {
    box-sizing: border-box;
    padding: 0.32rem 0.4rem;

    background: #ffffff;
    width: 13.12rem;

    border-radius: 0.02rem 0.02rem 0rem 0.02rem;
    .title {
      font-size: 0.2rem;
      text-align: center;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      line-height: 0.28rem;
      margin-bottom: 0.24rem;
    }
    .msg {
      font-size: 0.14rem;
      text-align: center;

      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
      line-height: 0.22rem;
      margin-bottom: 0.24rem;
      span:first-child {
        // margin-right: 0.32rem;
      }
    }
    ul {
      margin-top: 0.7rem;
      border-bottom: 1px solid #eeeeee;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 0.55rem;
        line-height: 0.55rem;
        border-top: 1px solid #eeeeee;
        div {
          font-size: 0.14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 0.22rem;
          img {
            width: 0.16rem;
            margin-left: 0.08rem;
          }
          span {
            margin-left: 1.02rem;
          }
        }
        span {
          font-size: 0.14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 0.22rem;
        }
        span:nth-child(1) {
        }
      }
    }
    .downloadIcon {
      display: flex;
      section{
        display: flex;
        align-items: center;
      }
    }
    .iframe {
      /deep/p {
        font-size: 0.14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // color: rgba(0, 0, 0, 0.45);
        line-height: 0.22rem;
      }

      /deep/ img {
        max-width: 12rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .home {
    margin: 0;
    padding: 0;
    .navigation {
      display: none;
    }
    .content {
      margin: 0;
      padding: 0.24rem;
      // margin-bottom: 0.5rem;
      width: auto;
      height: auto;
      .title {
        font-size: 0.16rem;

        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
        line-height: 0.24rem;
        margin-bottom: 0.16rem;
      }
      .msg {
        font-size: 0.14rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 0.22rem;
        margin-bottom: 0.16rem;
      }
      .iframe {
        /deep/ img {
          max-width: 3.2rem;
        }
        ul {
          margin-top: 0.1rem;
        }
      }
      ul {
        margin-top: 0.24rem;
        border: none;
        li {
          border: none;
          flex-direction: column;
          align-items: flex-start;
          height: auto;

          span {
            margin-top: 0.16rem;
          }
          .downloadIcon {
            display: flex;
            flex-direction: column-reverse;

            span {
              margin: 0;
              margin-top: 0.08rem;
              margin-bottom: 0.16rem;
            }
            section {
              width: 3.75rem;
              height: 0.4rem;
              background: #f6f6f6;
              transform: translate(-0.24rem);
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                margin: 0;
                font-size: 0.14rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.9);
                line-height: 0.22rem;
              }
              img{
                width: .16rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
